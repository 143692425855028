
import coin from '../assets/img/btc.png'
import ChartCoin from '../assets/img/chartCoin.svg'
import Calculator from './Calculator'
import BTC from '../assets/HeroCoins/BTC.svg'
import DOGE from '../assets/HeroCoins/DOGE.svg'

const Landing = () => {
    return (
        // Hero
        <section className="relative pt-4">
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                {/* Hero content */}
                <div className="pt-32 lg:pb-12 md:pt-40 md:pb-15">
                    <div className='grid lg:grid-cols-2 relative lg:mb-5 pb-0' >
                    <div>
                        <div className='heroMetaBox flex pt-10' >
                            <div className='heroChartCoin heroBox lg:mr-2' >
                                <div className='flex items-center' >
                                    <img width={35} height={35} className='mr-2' src={DOGE} />
                                    <div>
                                        <b className='HCC_CoinTitle' >Doge Coin</b>
                                    </div>
                                </div>
                                <img  src={ChartCoin} className='w-100 my-3 mb-5' />
                                <b className='HCC_Footer' >$0.16</b>
                            </div>
                            <div className='lg:ml-2' >
                                <Calculator/>
                            </div>
                        </div>
                        <svg className='HomeHeroImg' width="910" height="898" viewBox="0 0 910 898" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="245" y="191" width="289" height="413" rx="18.7326" className='heroBack'/>
                            <line x1="141.268" y1="448.808" x2="141.268" y2="511.07" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="192.796" y1="448.808" x2="192.796" y2="511.07" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="244.321" y1="448.808" x2="244.321" y2="511.07" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="295.848" y1="448.808" x2="295.848" y2="511.07" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="347.376" y1="448.808" x2="347.376" y2="511.07" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="398.903" y1="448.808" x2="398.903" y2="511.07" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="167.032" y1="458.469" x2="167.032" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="146.636" y1="484.233" x2="146.636" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="172.399" y1="484.233" x2="172.399" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="198.163" y1="484.233" x2="198.163" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="223.927" y1="484.233" x2="223.927" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="249.69" y1="484.233" x2="249.69" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="275.454" y1="484.233" x2="275.454" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="301.216" y1="484.233" x2="301.216" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="326.979" y1="484.233" x2="326.979" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="352.743" y1="484.233" x2="352.743" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="378.507" y1="484.233" x2="378.507" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="152.003" y1="484.233" x2="152.003" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="177.766" y1="484.233" x2="177.766" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="203.53" y1="484.233" x2="203.53" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="229.294" y1="484.233" x2="229.294" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="255.057" y1="484.233" x2="255.057" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="280.821" y1="484.233" x2="280.821" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="306.585" y1="484.233" x2="306.585" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="332.346" y1="484.233" x2="332.346" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="358.11" y1="484.233" x2="358.11" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="383.874" y1="484.233" x2="383.874" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="157.37" y1="484.233" x2="157.37" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="183.134" y1="484.233" x2="183.134" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="208.897" y1="484.233" x2="208.897" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="234.661" y1="484.233" x2="234.661" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="260.425" y1="484.233" x2="260.425" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="286.188" y1="484.233" x2="286.188" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="311.952" y1="484.233" x2="311.952" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="337.714" y1="484.233" x2="337.714" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="363.477" y1="484.233" x2="363.477" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="389.241" y1="484.233" x2="389.241" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="403.196" y1="484.233" x2="403.196" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="162.737" y1="484.233" x2="162.737" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="188.501" y1="484.233" x2="188.501" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="214.264" y1="484.233" x2="214.264" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="240.028" y1="484.233" x2="240.028" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="265.792" y1="484.233" x2="265.792" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="291.555" y1="484.233" x2="291.555" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="317.319" y1="484.233" x2="317.319" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="343.083" y1="484.233" x2="343.083" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="368.845" y1="484.233" x2="368.845" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="394.608" y1="484.233" x2="394.608" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="218.559" y1="458.469" x2="218.559" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="270.085" y1="458.469" x2="270.085" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="321.612" y1="458.469" x2="321.612" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <line x1="373.139" y1="458.469" x2="373.139" y2="501.409" stroke="#92A7AA" stroke-width="0.536738"/>
                            <g filter="url(#filter0_f_308_812)">
                            <ellipse cx="375.9" cy="449.953" rx="9.09485" ry="9.76867" transform="rotate(29 375.9 449.953)" fill="url(#paint0_linear_308_812)"/>
                            </g>
                            <g filter="url(#filter1_d_308_812)">
                            <path d="M376.092 450.853C347.388 437.067 354.222 474.108 313.217 463.26C303.563 460.706 296.262 488.298 283.146 488.298C270.03 488.298 255.13 477.046 246.386 477.046C237.642 477.046 227.44 494.968 211.409 494.968C203.869 494.968 191.567 475.667 181.999 475.667C172.431 475.667 163.821 495.669 156.029 488.298" stroke="url(#paint1_linear_308_812)" stroke-width="2.9306" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            <circle cx="375.572" cy="449.362" r="7.51433" transform="rotate(29 375.572 449.362)" fill="url(#paint2_linear_308_812)"/>
                            <g filter="url(#filter2_f_308_812)">
                            <ellipse cx="107.618" cy="40.3132" rx="107.618" ry="40.3132" transform="matrix(0.546541 -0.837432 -0.837432 -0.546541 524.938 625.796)" fill="#64F0BF"/>
                            </g>
                            <g filter="url(#filter3_f_308_812)">
                            <ellipse cx="53.1215" cy="19.8991" rx="53.1215" ry="19.8991" transform="matrix(0.546541 -0.837432 -0.837432 -0.546541 217.078 265.723)" fill="#64F0BF" fill-opacity="0.8"/>
                            </g>
                            <g filter="url(#filter4_f_308_812)">
                            <ellipse cx="75.6708" cy="28.346" rx="75.6708" ry="28.346" transform="matrix(0.546541 -0.837432 -0.837432 -0.546541 263.416 604.021)" fill="#64F0BF" fill-opacity="0.8"/>
                            </g>
                            <defs>
                            <filter id="filter0_f_308_812" x="323.702" y="397.398" width="104.398" height="105.111" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                            <feGaussianBlur stdDeviation="21.4695" result="effect1_foregroundBlur_308_812"/>
                            </filter>
                            <filter id="filter1_d_308_812" x="144.796" y="446.269" width="242.53" height="69.7012" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="9.76867"/>
                            <feGaussianBlur stdDeviation="4.88434"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0.0456816 0 0 0 0 0.0410428 0 0 0 0 0.12686 0 0 0 0.54362 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_308_812"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_308_812" result="shape"/>
                            </filter>
                            <filter id="filter2_f_308_812" x="190.73" y="129.407" width="718.53" height="768.469" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                            <feGaussianBlur stdDeviation="145.718" result="effect1_foregroundBlur_308_812"/>
                            </filter>
                            <filter id="filter3_f_308_812" x="32.2944" y="0.882523" width="394.306" height="418.958" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                            <feGaussianBlur stdDeviation="81.8362" result="effect1_foregroundBlur_308_812"/>
                            </filter>
                            <filter id="filter4_f_308_812" x="0.193069" y="226.76" width="561.684" height="596.799" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                            <feGaussianBlur stdDeviation="116.574" result="effect1_foregroundBlur_308_812"/>
                            </filter>
                            <linearGradient id="paint0_linear_308_812" x1="376.749" y1="465.24" x2="387.642" y2="456.498" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#6EFF61" stop-opacity="0.01"/>
                            <stop offset="1" stop-color="#5AFF5C"/>
                            </linearGradient>
                            <linearGradient id="paint1_linear_308_812" x1="375.507" y1="514.51" x2="156.029" y2="514.51" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#A8FDA9"/>
                            <stop offset="1" stop-color="#74FF00" stop-opacity="0.01"/>
                            </linearGradient>
                            <linearGradient id="paint2_linear_308_812" x1="383.086" y1="449.362" x2="367.501" y2="449.362" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#8CFFEB"/>
                            <stop offset="1" stop-color="#B6FF9B"/>
                            </linearGradient>
                            </defs>
                        </svg>   

                    </div>
                    {/* Section header */}
                    <div className="text-right lg:pb-12 lg:pb-16 pt-5">
                        <h1 className="HeroHeading mt-5" data-aos="zoom-y-out" data-aos-delay="100"><span className="PrimaryText">صرافی ارزدیجیتال آلمااکس </span>
                        خرید 
                        <br />
                         و فروش آسان ارزهای دیجیتال
                        </h1>
                        <div className="max-w-3xl mx-auto">
                        <p className="HeroCap mt-3 mb-3" data-aos="zoom-y-out" data-aos-delay="350" dir='rtl'>
                        در اینجا هیچ مرزی برای پرداخت‌های ارزی وجود ندارد. تمام پرداخت‌های پی‌پال، پرفکت‌مانی، ویزا و مسترکارت، وایز و ارز دیجیتال به صورت هوشمند و آنی انجام خواهد شد. سرویس‌های دیگری مثل خرید از سایت‌های خارجی، پرداخت هزینه تعیین وقت سفارت‌ها...
                        </p>
                        <div className="max-w-xs mx-auto sm:max-w-none" data-aos="zoom-y-out" data-aos-delay="500">
                            <a className="BuTTon btnDark mt-4 bg-blue-600 hover:bg-blue-700 w-full lg:mb-4 sm:w-auto sm:mb-0" href="https://app.alma.exchange">ورود به حساب کاربری</a>
                            <a className="BuTTon btnGreen mt-4 bg-blue-600 hover:bg-blue-700 w-full mb-4 sm:w-auto sm:mb-0 lg:ml-3" href="https://app.alma.exchange">همین حالا ثبت نام کن</a>
                        </div>
                        </div>
                    </div>
        
                    </div>
                </div>
            </div>
        </section>        
    );
}

export default Landing;
