
import TabsImage from '../assets/img/HomeTabs.png'
import TabIcon1 from '../assets/img/tabIcon1.svg'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const HomeTabs = () => {
    return (
        // Home Tabs
        <section className="relative">
            {/* Section background (needs .relative class on parent and next sibling elements) */}
            <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
            <div className='grid lg:grid-cols-2' >
                <div>
                    <img className='mb-3 TabsImage' src={TabsImage} alt="almaex" data-aos="zoom-y-out" data-aos-delay="300"  />
                </div>
                <div className='HomeTabs' >
                <div className="text-right" data-aos="zoom-y-out" data-aos-delay="100">
                    <h4 className='subTitle' >خدمات وبسایت ما</h4>
                    <h2 className='singleTitle'>پوشش کامل خدمات ارز شما</h2>
                </div>

                <Tabs className={"mt-5"} data-aos="zoom-y-out" data-aos-delay="200">
                    <TabList>
                    <Tab>خرید و فروش ارزدیجیتال</Tab>
                    <Tab>خرید پرفکت مانی</Tab>
                    <Tab>نرخ لحظه ای ارز</Tab>
                    <Tab>خرید تتر</Tab>
                    </TabList>

                    <TabPanel>
                    <div className="text-right" dir='rtl'>
                        <img className='mb-3 TabsIcon' src={TabIcon1} width={58} alt="almaex" />
                        <h5 className='tabContentTitle' >
                        خرید و فروش ارزهای دیجیتال آسان‌تر از همیشه
                        </h5>
                        <p className='tabContentCap' >
                        لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است.
                        </p>

                        <div className="TabFooter lg:flex items-center mt-6">
                        <div className='flex items-center' >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.97 18.33C21.97 18.69 21.89 19.06 21.72 19.42C21.55 19.78 21.33 20.12 21.04 20.44C20.55 20.98 20.01 21.37 19.4 21.62C18.8 21.87 18.15 22 17.45 22C16.43 22 15.34 21.76 14.19 21.27C13.04 20.78 11.89 20.12 10.75 19.29C9.6 18.45 8.51 17.52 7.47 16.49C6.44 15.45 5.51 14.36 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.27 13.75 11.79 14.24 12.32 14.69C12.84 15.13 13.27 15.43 13.61 15.61C13.66 15.63 13.72 15.66 13.79 15.69C13.87 15.72 13.95 15.73 14.04 15.73C14.21 15.73 14.34 15.67 14.45 15.56L15.21 14.81C15.46 14.56 15.7 14.37 15.93 14.25C16.16 14.11 16.39 14.04 16.64 14.04C16.83 14.04 17.03 14.08 17.25 14.17C17.47 14.26 17.7 14.39 17.95 14.56L21.26 16.91C21.52 17.09 21.7 17.3 21.81 17.55C21.91 17.8 21.97 18.05 21.97 18.33Z" stroke="#031B29" stroke-width="1.5" stroke-miterlimit="10"/>
                            <path d="M18.5 9C18.5 8.4 18.03 7.48 17.33 6.73C16.69 6.04 15.84 5.5 15 5.5" stroke="#58BD7D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M22 9C22 5.13 18.87 2 15 2" stroke="#58BD7D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <span className='mr-2'>تماس با پشتیبانی</span>
                        </div>

                        <a className="BuTTon btnGreen bg-blue-600 hover:bg-blue-700 w-full sm:w-auto sm:mb-0 mr-5" href="https://app.alma.exchange">معامله کنید</a>
                        </div>
                    </div>
                    </TabPanel>
                    <TabPanel>
                    <h2>Any content 2</h2>
                    </TabPanel>
                </Tabs>


                </div>
            </div>
            </div>

            <div className="seprator">
            <svg width="1157" height="1" viewBox="0 0 1157 1" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line y1="0.75" x2="1157" y2="0.75" stroke="url(#paint0_linear_244_3624)" stroke-width="0.5"/>
            <defs>
            <linearGradient id="paint0_linear_244_3624" x1="-20" y1="1" x2="1157" y2="1" gradientUnits="userSpaceOnUse">
            <stop stop-color="white" stop-opacity="0"/>
            <stop offset="0.467466" stop-color="#031B29" stop-opacity="0.43"/>
            <stop offset="1" stop-color="white" stop-opacity="0"/>
            </linearGradient>
            </defs>
            </svg>
            </div>
            </section>

    );
}

export default HomeTabs;
