

const Comments = () => {

    return (
        // Hero
        <section className="relative mt-20 lg:mt-40 pt-1 max-w-6xl mx-auto px-4 sm:px-6">
            <div className="lg:grid grid-cols-2" dir="rtl" >


                <div className="lg:pl-20" dir="rtl" >
                    <div class="text-right" data-aos="zoom-y-out" data-aos-delay="200" >
                        <h4 class="subTitle singleSubTitle">کامنت ها</h4>
                        <h2 class="singleTitle singlePageTitle" dir='rtl'>
                            نظرات کاربران
                        </h2>
                    </div>
                    <p className="HeroCap text-right mt-2" dir="rtl" data-aos="zoom-y-out" data-aos-delay="300" >
                    آیا میدانید در صورتی که عضو صرافی آلمااکس باشید و احراز هویت خود را تکمیل کرده باشید میتوانید با ارسال هر نظر برای ما ارز دیجیتال دریافت نمایید ؟کافیست بر روی گزینه ارسال نظر کلیک کنید و سپس فرم مورد نظر را پر کرده و بروی دکمه ثبت نظر کلیک نمایید.
                    </p>

                    <div class="max-w-xs mx-auto sm:max-w-none mt-4" data-aos="zoom-y-out" data-aos-delay="400">
                        <a class="BuTTon btnGreen bg-blue-600 hover:bg-blue-700 w-full mb-4 sm:w-auto sm:mb-0 lg:ml-3" href="#0">ارسال نظر</a>
                        <a class="BuTTon btnDark bg-blue-600 hover:bg-blue-700 w-full lg:mb-4 sm:w-auto sm:mb-0" href="#0">مشاهده نظرات</a>
                    </div>
                </div>



                <div className="comments mt-10 lg:mt-0" >
                    <div className="commentsDivs"  data-aos="zoom-y-out" data-aos-delay="400"  >
                        <div className="commentItem p-6 mb-6" >
                            <div className="commentHeading flex items-center"  dir="rtl">
                                <svg className="commentAuthorIcon ml-3" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24" ><path d="M12 2C6.579 2 2 6.579 2 12s4.579 10 10 10 10-4.579 10-10S17.421 2 12 2zm0 5c1.727 0 3 1.272 3 3s-1.273 3-3 3c-1.726 0-3-1.272-3-3s1.274-3 3-3zm-5.106 9.772c.897-1.32 2.393-2.2 4.106-2.2h2c1.714 0 3.209.88 4.106 2.2C15.828 18.14 14.015 19 12 19s-3.828-.86-5.106-2.228z"></path></svg>
                                <div>
                                    <span className="commentAuthor block" >
                                    احمدرضا حسینی
                                    </span>
                                    <span className="commentDate" >
                                       ۱۰ اردیبهشت ۱۴۰۲
                                    </span>
                                </div>
                            </div>
                            <div className="commentBody mt-4" dir="rtl" >
                                <p>
                                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است.
                                </p>
                            </div>
                        </div>

                        <div className="commentItem p-6 mb-6" >
                            <div className="commentHeading flex items-center"  dir="rtl">
                                <svg className="commentAuthorIcon ml-3" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24" ><path d="M12 2C6.579 2 2 6.579 2 12s4.579 10 10 10 10-4.579 10-10S17.421 2 12 2zm0 5c1.727 0 3 1.272 3 3s-1.273 3-3 3c-1.726 0-3-1.272-3-3s1.274-3 3-3zm-5.106 9.772c.897-1.32 2.393-2.2 4.106-2.2h2c1.714 0 3.209.88 4.106 2.2C15.828 18.14 14.015 19 12 19s-3.828-.86-5.106-2.228z"></path></svg>
                                <div>
                                    <span className="commentAuthor block" >
                                    احمدرضا حسینی
                                    </span>
                                    <span className="commentDate" >
                                       ۱۰ اردیبهشت ۱۴۰۲
                                    </span>
                                </div>
                            </div>
                            <div className="commentBody mt-4" dir="rtl" >
                                <p>
                                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است.
                                </p>
                            </div>
                        </div>

                        <div className="commentItem p-6 mb-6" >
                            <div className="commentHeading flex items-center"  dir="rtl">
                                <svg className="commentAuthorIcon ml-3" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24" ><path d="M12 2C6.579 2 2 6.579 2 12s4.579 10 10 10 10-4.579 10-10S17.421 2 12 2zm0 5c1.727 0 3 1.272 3 3s-1.273 3-3 3c-1.726 0-3-1.272-3-3s1.274-3 3-3zm-5.106 9.772c.897-1.32 2.393-2.2 4.106-2.2h2c1.714 0 3.209.88 4.106 2.2C15.828 18.14 14.015 19 12 19s-3.828-.86-5.106-2.228z"></path></svg>
                                <div>
                                    <span className="commentAuthor block" >
                                    احمدرضا حسینی
                                    </span>
                                    <span className="commentDate" >
                                       ۱۰ اردیبهشت ۱۴۰۲
                                    </span>
                                </div>
                            </div>
                            <div className="commentBody mt-4" dir="rtl" >
                                <p>
                                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است.
                                </p>
                            </div>
                        </div>

                        <div className="commentItem p-6 mb-6" >
                            <div className="commentHeading flex items-center"  dir="rtl">
                                <svg className="commentAuthorIcon ml-3" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24" ><path d="M12 2C6.579 2 2 6.579 2 12s4.579 10 10 10 10-4.579 10-10S17.421 2 12 2zm0 5c1.727 0 3 1.272 3 3s-1.273 3-3 3c-1.726 0-3-1.272-3-3s1.274-3 3-3zm-5.106 9.772c.897-1.32 2.393-2.2 4.106-2.2h2c1.714 0 3.209.88 4.106 2.2C15.828 18.14 14.015 19 12 19s-3.828-.86-5.106-2.228z"></path></svg>
                                <div>
                                    <span className="commentAuthor block" >
                                    احمدرضا حسینی
                                    </span>
                                    <span className="commentDate" >
                                       ۱۰ اردیبهشت ۱۴۰۲
                                    </span>
                                </div>
                            </div>
                            <div className="commentBody mt-4" dir="rtl" >
                                <p>
                                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>


            </div>


        </section>        
    );
}

export default Comments;
