import VideoPlaceHolder from '../assets/img/VideoPlaceHolder.png'

const HomeCaptions = () => {
    return (
        // HomeCaptions
        <section>
            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:mt-20 mb-0">
            <div className="pb-12 md:pb-20">
                <div className="grid lg:grid-cols-2 flex items-center" dir='rtl'>
                    <div className='text-right lg:pl-12 mt-12 mb-12'  data-aos="zoom-y-out" data-aos-delay="300" >
                    <h2 className="singleTitle mb-4">با تتر بیشتر آشنا شوید</h2>
                        <p className='HeroCap maxWcap'>
                        تتر یا USDT یک استیبل کوین یا رمزارز قیمت‌ثابت است که پشتوانهٔ آن دلار آمریکاست. به همین دلیل قیمت آن همواره برابر با یک دلار است. تتر بزرگترین استیبل کوین بازار ارزهای دیجیتال است و بالاترین میزان خرید و فروش روزانه (حتی بیشتر از بیت کوین) را در میان تمام ارزهای دیجیتال دارد. تتر با هدف کاهش ریسک نوسانات شدید قیمتی در بازار ارزهای دیجیتال به وجود آمده است. همچنین از تتر به‌عنوان جفت‌ارزی برای مبادلهٔ سایر رمزارزها در صرافی‌های ارز دیجیتال استفاده می‌شود. این استیبل کوین در سال ۲۰۱۴ بر بستر لایه اومنی بلاک چین بیت کوین به وجود آمد؛ اما در حال حاضر بلاک چین‌های بسیاری مانند اتریوم، ترون، سولانا، الگورند و آوالانچ میزبان توکن Tether‌ هستند.
                        </p>
                    </div>
                    <img className='w-full'  src={VideoPlaceHolder}  alt="با تتر بیشتر آشنا شوید"  data-aos="zoom-y-out" data-aos-delay="300"  />

                </div>
            </div>
            </div>
        </section>
    );
}

export default HomeCaptions;
