
import React, { useState, useEffect } from 'react';
import CoinChart from '../assets/img/CoinChart.svg'
import irFlag from '../assets/img/irFlag.png'

const CoinHero = () => {

  const [inputValue, setInputValue] = useState('');
  const [result, setResult] = useState('');
  const [placeholderValue, setPlaceholderValue] = useState('Loading...');
  const [sellRate, setSellRate] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
        try {
            const response = await fetch('https://app.alma.exchange/api/coins/usdt');
            if (!response.ok) {
            throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            const usdtSellRate = data.data.sell_rate;
            setSellRate(usdtSellRate);
            const formattedSellRate = usdtSellRate.toLocaleString();
            setPlaceholderValue(formattedSellRate);
            setResult(formattedSellRate);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        };

        fetchData();
    }, []); 

    const handleKeyUp = (event) => {
        const inputValue = event.target.value;
        const inputNumber = parseFloat(inputValue.replace(/,/g, '').replace(/[۰-۹]/g, '')); 
        const formattedInput = inputNumber.toLocaleString();
        setInputValue(formattedInput);

        if (!isNaN(inputNumber)) {
        const resultValue = inputNumber / sellRate;
        const formattedResult = resultValue.toLocaleString();
        setResult(formattedResult);
        }else{
            setInputValue(''); 
        }
    };
    return (
        // Hero
        <section className="relative pt-4 mt-20 pt-20 max-w-6xl mx-auto px-4 sm:px-6 mb-10 lg:mb-0">
            <div className="" data-aos="zoom-y-out" data-aos-delay="100">
                <div className='coinHeroBar flex items-center' dir='rtl' >
                    <div className='flex items-center' >
                        <img className='ml-3' src='https://app.almaex.net/api/coins/32x32/usdt.png' ></img>
                        خرید و فروش تتر
                    </div>

                    <span className='coinBarPrice mr-auto' >
                    {placeholderValue}
                        <small>تومان</small>
                    </span>
                </div>
            </div>

            <div className='chartBox lg:flex' dir='rtl' >
                <div className='lg:w-3/4 lg:pl-12' data-aos="zoom-y-out" data-aos-delay="300">
                    <div className='chartHeader' dir='ltr' >
                        <span className='chName block' >Tether / U.S. dollar</span>
                        <span className='chNum block' > {placeholderValue}</span>
                    </div>
                    <img className='w-full' src={CoinChart} ></img>
                </div>
                <div className='lg:w-1/4' data-aos="zoom-y-out" data-aos-delay="500">
                    <div className='calculator p-5' >
                        <div className='relative' >
                            <label for="rial" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white labelStyle">پرداخت می کنید
                                <small className='uniter' >(تومان)</small>
                            </label>
                            <input value={inputValue}  onChange={(event) => setInputValue(event.target.value)} onKeyUp={handleKeyUp} type="tel" pattern="[0-9]*" dir='rtl' id="rial" className="inputStyle bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={placeholderValue} required="" />
                            <img className='inputUnit' src={irFlag} />
                        </div>
                        <div className='relative mt-5' >
                            <label for="crypto" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white labelStyle">دریافت می کنید</label>
                            <input type="text"  value={result} id="crypto" className="inputStyle bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="1" required="" readOnly />
                            <img className='inputUnit' src='https://app.almaex.net/api/coins/32x32/usdt.png' />
                        </div>

                        <small className='calHint mt-5 block' >
                            قیمت خرید هر واحد تتر:
                            <b className='mr-1' >
                            {placeholderValue}
                            </b>
                        </small>
                        <button type='button' className='tradeButton mt-4 bg-blue-600 hover:bg-blue-700 w-full sm:w-auto sm:mb-0 lg:ml-3'>
                            برای خرید ابتدا وارد شوید
                        </button>
                    </div>
                </div>
            </div>
        </section>        
    );
}

export default CoinHero;
