import Banner from '../assets/img/HomeBanner.png'

const HomeBanner = () => {
    return (
        // HomeBanner
        <section>
            <div className="max-w-6xl mx-auto px-4 sm:px-6 mt-20 mb-0">
            <div className="lg:pb-12 md:pb-20">
                <div className="HomeBanner lg:flex" data-aos="zoom-y-out" data-aos-delay="100">
                    <img  src={Banner}  alt="با خاطری آسوده معامله کنید" />
                    <div className='text-right lg:pl-12 mt-12 mb-12 px-5 lg:px-0 pt-12 lg:pt-0 lg:pr-12 lg:pt-5' >
                        <h3 className='singleTitle PrimaryText mt-18'>با خاطری آسوده معامله کنید</h3>
                        <p className='HeroCap maxWcap'>
                        امنیت مبادلات و حفظ دارایی‌های مشتریان اولویت اول آلمااکس است. این عامل مهم چه در طول توسعه و چه در فاز بهره برداری از سامانه بطور پیوسته مورد رصد تیم امنیت آلما اکس قرار می‌گیرد.
                        </p>
                        <div className="max-w-xs mx-auto sm:max-w-none " data-aos="zoom-y-out" data-aos-delay="300">
                        <a className="BuTTon btnGreen mt-4 bg-blue-600 hover:bg-blue-700 w-full lg:mb-4 sm:w-auto sm:mb-0 lg:mr-3" href="#0">ثبت نام</a>
                        <a className="BuTTon btnDark mt-4 bg-blue-600 hover:bg-blue-700 w-full mb-4 sm:w-auto sm:mb-0" href="#0">جزییات بیشتر</a>
                    </div>
                    </div>
                </div>
            </div>
            </div>
        </section>
    );
}

export default HomeBanner;
