import React, { useEffect } from 'react'; // Import useEffect from react
import AppRouter from './router';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS
import Header from './components/ui/Header';
import Footer from './components/ui/Footer';
import './App.css';

function App() {
  useEffect(() => {
    AOS.init({
      // Global settings for AOS
      // You can customize AOS options here
    });
  }, []);

  return (
    <main className="App">
        <Header />
        <AppRouter /> {/* Render the HomeView component */}
        <Footer />
    </main>
  );
}

export default App;
