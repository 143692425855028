import HomeBanner from '../components/HomeBanner';
import HomeBlog from '../components/HomeBlog';
import HomeCaptions from '../components/HomeCaptions';
import Accordions from '../components/Accordions';
import CoinHero from '../components/CoinHero';
import CoinBanner from '../components/CoinBanner';
import CoinVideo from '../components/CoinVideo';
import CoinSteps from '../components/CoinSteps';
import CoinFeatures from '../components/CoinFeatures';
import CoinComments from '../components/CoinComments';

const Coin = () => {
    return (
        <div className='landing' >
            {/* Hero */}
            <CoinHero />     
            {/* CoinBanner */}
            <CoinBanner />     
            {/* Coin Video */}
            <CoinVideo />     
            {/* Coins Steps */}
            <CoinSteps />  
            {/* Coin Features */}
            <CoinFeatures />  
            {/* Comments */}
            <CoinComments />
            {/* Accordions */}
            <Accordions /> 
        </div>
    );
}

export default Coin;