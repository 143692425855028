
import featuresBanner from '../assets/img/coinFeatures.png'

const CoinFeatures = () => {

    const RED = {
        color: '#FF7777',
    };

    const GREEN = {
        color: '#58BD7D',
    };

    const BLUE = {
        color: '#66C8FF',
    };



    return (
        // Hero
        <section className="relative mt-20 pt-1 max-w-6xl mx-auto px-4 sm:px-6">
            {/* <h3 className='singleTitle' >معرفی جامع ارز دیجیتال تتر (Tether)</h3> */}
            <div class="text-center" data-aos="zoom-y-out" data-aos-delay="200" >
                <h4 class="subTitle singleSubTitle noBack">مراحل خرید تتر</h4>
                <h2 class="singleTitle singlePageTitle" dir='rtl'>
                    چگونه تتر بخریم؟
                </h2>
            </div>

            <div className='featureBox relative lg:flex items-center mt-10' data-aos="zoom-y-out" data-aos-delay="400" >
                <div className='lg:grid grid-cols-3 lg:pr-60 items-center' >
                    <div className='featureItem px-10' >
                        <svg width="92" height="92" viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="46" cy="46" r="46" fill="#FF7777" fill-opacity="0.26"/>
                        <path d="M57.5 60.1483H56.0433C54.51 60.1483 53.0533 60.7425 51.98 61.8158L48.7025 65.0551C47.2075 66.5309 44.7733 66.5309 43.2783 65.0551L40.0008 61.8158C38.9275 60.7425 37.4517 60.1483 35.9375 60.1483H34.5C31.3183 60.1483 28.75 57.5992 28.75 54.4559V33.545C28.75 30.4016 31.3183 27.8525 34.5 27.8525H57.5C60.6817 27.8525 63.25 30.4016 63.25 33.545V54.4559C63.25 57.58 60.6817 60.1483 57.5 60.1483Z" stroke="#FF7777" stroke-width="2.875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path opacity="0.4" d="M36.4167 41.5567C36.4167 39.7742 37.8734 38.3174 39.6559 38.3174C41.4384 38.3174 42.8951 39.7742 42.8951 41.5567C42.8951 45.16 37.7776 45.5433 36.6467 48.9742C36.4167 49.6833 37.0109 50.3925 37.7584 50.3925H42.8951" stroke="#FF7777" stroke-width="2.875" stroke-linecap="round" stroke-linejoin="round"/>
                        <path opacity="0.4" d="M53.7429 50.3733V39.4292C53.7429 38.9309 53.417 38.49 52.9379 38.3558C52.4587 38.2216 51.9412 38.4133 51.6729 38.835C50.2929 41.0583 48.7979 43.5883 47.4946 45.8116C47.2837 46.1758 47.2837 46.655 47.4946 47.0192C47.7054 47.3833 48.1079 47.6132 48.5487 47.6132H55.5829" stroke="#FF7777" stroke-width="2.875" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                        <h6 style={RED} className='featureHeading' >
                            پشتیبانی ۲۴ ساعته 
                        </h6>
                        <p className='featureCaption' >
                        کارشناسان آلمااکس در هر ساعت از شبانه‌روز پاسخ‌گوی کاربران هستند.
                        </p>
                    </div>

                    <div className='featureItem px-10' >
                        <svg width="92" height="92" viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="46" cy="46" r="46" fill="#66DAFF" fill-opacity="0.26"/>
                        <path d="M63.0771 44.3132C63.0771 53.6857 56.273 62.4641 46.9771 65.0324C46.3446 65.2049 45.6546 65.2049 45.0221 65.0324C35.7263 62.4641 28.9221 53.6857 28.9221 44.3132V35.8991C28.9221 34.3274 30.1105 32.5449 31.5863 31.9507L42.2621 27.5808C44.6579 26.6033 47.3604 26.6033 49.7563 27.5808L60.4321 31.9507C61.8888 32.5449 63.0963 34.3274 63.0963 35.8991L63.0771 44.3132Z" stroke="#66C8FF" stroke-width="2.875" stroke-linecap="round" stroke-linejoin="round"/>
                        <g opacity="0.4">
                        <path d="M46.0001 46.9584C48.1172 46.9584 49.8334 45.2422 49.8334 43.1251C49.8334 41.008 48.1172 39.2917 46.0001 39.2917C43.883 39.2917 42.1667 41.008 42.1667 43.1251C42.1667 45.2422 43.883 46.9584 46.0001 46.9584Z" stroke="#66C8FF" stroke-width="2.875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M46.0002 46.9585V52.7085" stroke="#66C8FF" stroke-width="2.875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        </svg>

                        <h6 style={BLUE}  className='featureHeading' >
                        امنیت بالا
                        </h6>
                        <p className='featureCaption' >
                        ارائه بیشترین ضریب امنیتی و حفظ اطلاعات کاربران همواره اولویت اصلی آلمااکس است.
                        </p>
                    </div>

                    <div className='featureItem px-10' >
                        <svg width="92" height="92" viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="46" cy="46" r="46" fill="#58BD7D" fill-opacity="0.26"/>
                        <path d="M58.5768 49.9709C57.7718 50.7567 57.3118 51.8876 57.4268 53.0951C57.5993 55.1651 59.4967 56.6792 61.5668 56.6792H65.2084V58.9601C65.2084 62.9276 61.9692 66.1667 58.0017 66.1667H35.9984C32.0309 66.1667 28.7917 62.9276 28.7917 58.9601V46.0609C28.7917 42.0934 32.0309 38.8542 35.9984 38.8542H58.0017C61.9692 38.8542 65.2084 42.0934 65.2084 46.0609V48.8209H61.3367C60.2634 48.8209 59.2859 49.2426 58.5768 49.9709Z" stroke="#58BD7D" stroke-width="2.875" stroke-linecap="round" stroke-linejoin="round"/>
                        <path opacity="0.4" d="M28.7917 47.786V39.0269C28.7917 36.7461 30.1909 34.7143 32.3184 33.9093L47.5367 28.1593C49.9134 27.2585 52.4626 29.0219 52.4626 31.5711V38.8544" stroke="#58BD7D" stroke-width="2.875" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M67.2395 50.7757V54.7242C67.2395 55.7783 66.3962 56.6408 65.3228 56.6791H61.5662C59.4962 56.6791 57.5987 55.1649 57.4262 53.0949C57.3112 51.8874 57.7712 50.7566 58.5762 49.9708C59.2853 49.2424 60.2628 48.8208 61.3362 48.8208H65.3228C66.3962 48.8591 67.2395 49.7216 67.2395 50.7757Z" stroke="#58BD7D" stroke-width="2.875" stroke-linecap="round" stroke-linejoin="round"/>
                        <path opacity="0.4" d="M37.4167 47H50.8334" stroke="#58BD7D" stroke-width="2.875" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <h6 style={GREEN} className='featureHeading' >
                        کیف‌پول تتر و تومان  
                        </h6>
                        <p className='featureCaption' >
                        با استفاده از کیف‌ تتر و تومانی، تبادل‌های شما به‌صرفه‌تر خواهد بود.
                        </p>
                    </div>
                </div>
                <img className='featureIMG h-full' src={featuresBanner} />
            </div>
        </section>        
    );
}

export default CoinFeatures;
