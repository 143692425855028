import React, { useState, useEffect } from 'react';
import BTC from '../assets/HeroCoins/BTC.svg'
import ETH from '../assets/HeroCoins/ETH.svg'
import XRP from '../assets/HeroCoins/XRP.svg'
import DOGE from '../assets/HeroCoins/DOGE.svg'
import LTC from '../assets/HeroCoins/LTC.svg'
import USDT from '../assets/HeroCoins/USDT.svg'

const CoinSlider = () => {

    const [coinItems, setDivs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
     
    useEffect(() => {
      const fetchData = async () => {
        
        function setIcon(asset){
            switch (asset) {
                case 'BTC':
                    return BTC;
                case 'ETH':
                    return ETH;
                case 'XRP':
                    return XRP;
                case 'DOGE':
                    return DOGE;
                case 'LTC':
                    return LTC;
                case 'USDT':
                    return USDT;
                default:
                    return null;
            }
        };
        
        try {
          const response = await fetch('https://app.alma.exchange/api/coins');
          if (!response.ok) {
            throw new Error('Failed to fetch data');
          }
          const data = await response.json();
          
          const acceptedAssets = ["BTC", "ETH" , "USDT" , "XRP" , "LTC" , "DOGE"];
          setDivs(data.data.filter(coin => acceptedAssets.includes(coin.asset)).map((coin, index) => (
            <div className='coinCard lg:text-right mb-7 lg:mb-0' dir='rtl' data-aos="zoom-y-out" data-aos-delay={index + "00"} key={index}>
              <img width={50} className="mb-3" src={setIcon(coin.asset)} alt={coin.asset} />
              <div className="coinCardTitle mb-3">{coin.name}<span class="coinSlug">{coin.asset}</span></div>
              <span class="coinCardPrice">{coin.buy_rate.toLocaleString().split('.')[0]}<small className='heroUnit' >تومان</small></span>
            </div>
          )));
          setIsLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      
      fetchData();
  
      return () => {};
    }, []); 

    return (
        // Hero
        <section className="relative">

        <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
          <div className="pt-0">
            {/* Conditional rendering of loader */}
            {isLoading ? (
               <div className='loaderWrapper' ><span class="loader"></span></div>
            ) : (
              <div className='grid grid-cols-2 lg:grid-cols-6 scrolls' >{coinItems}</div>
            )}
          </div>
        </div>
        <div className="seprator">
          <svg width="1157" height="1" viewBox="0 0 1157 1" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line y1="0.75" x2="1157" y2="0.75" stroke="url(#paint0_linear_244_3624)" stroke-width="0.5"/>
          <defs>
          <linearGradient id="paint0_linear_244_3624" x1="-20" y1="1" x2="1157" y2="1" gradientUnits="userSpaceOnUse">
          <stop stop-color="white" stop-opacity="0"/>
          <stop offset="0.467466" className='sepratorColor' stop-opacity="0.43"/>
          <stop offset="1" stop-color="white" stop-opacity="0"/>
          </linearGradient>
          </defs>
          </svg>
        </div>
      </section>      
    );
}

export default CoinSlider;
