import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import BTC from '../assets/HeroCoins/BTC.svg'

function CryptoToRialCalculator() {
    const [cryptoAmount, setCryptoAmount] = useState('');
    const [rialPrice, setRialPrice] = useState('');
    const [coins, setCoins] = useState([]);
    const [selectedCoin, setSelectedCoin] = useState(null);
    const [loading, setLoading] = useState(true); // Introduce loading state
    const [topCoin,setStatic] = useState('');

    useEffect(() => {
        fetchCoins();
    }, []);

    const fetchCoins = async () => {
        try {
            const response = await fetch('https://app.alma.exchange/api/coins');
            const data = await response.json();
            setCoins(data.data);
            // Set the first coin as the selected coin initially
            console.log(data.data[5]["asset"])
            setStatic(data.data[5]["buy_rate"].toLocaleString().split('.')[0])
            setSelectedCoin(data.data[0]);
            setLoading(false); // Set loading to false when data is fetched

        } catch (error) {
            console.error('Error fetching coins:', error);
        }
    };

    const handleCryptoAmountChange = (e) => {
        const amount = parseFloat(e.target.value);
        setCryptoAmount(amount);
        if (selectedCoin) {
            const rialAmount = amount * selectedCoin.rate;
            setRialPrice(rialAmount.toLocaleString());
        }
    };

    const handleCoinChange = (selectedValue) => {
        const selectedCoin = coins.find(coin => coin.name === selectedValue);
        setSelectedCoin(selectedCoin);
        // Reset rial price when the selected coin changes
        setRialPrice('');
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            // selectbox ==>
            backgroundColor: 'transparent', 
            borderRadius: '10px', 
            border: '0 !important',
            direction: 'rtl',
            fontSize: '13px',
            outline: 'none !important', // Remove default focus outline
            boxShadow: 'none !important', // Add box shadow when focused
            border: 'none !important',
            minWidth: '120px',
            paddingRight: '0',
            color: 'white'
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            padding: '0', // Adjust padding as needed
            paddingLeft: '10px',
            color: 'white', // Change color of the arrow icon
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            display: 'none', // Hide the default separator line
        }),
        option: (provided, state) => ({
            ...provided,
            // options ==>
            backgroundColor: state.isSelected ? '#58BD7D' : '#0A293A', 
            color: state.isSelected ? 'white' : 'black', 
            margin: '0px 0',
            padding: '0',
            '&:hover': {
                backgroundColor: '#031B29', // Change background color on hover
            },
        }),
        singleValue: (provided, state) => ({
            ...provided,
            // input of selectbox ==>
            backgroundColor: 'transparent',
            color: 'white', 
            fontWeight: '300',
            padding: '0px', 
        }),
        menu: (provided, state) => ({
            ...provided,
            // Main menubar
            overflow: 'hidden' ,
            borderRadius: '15px' ,
            padding: '0'
        }),
        menuList: (provided, state) => ({
            ...provided,
            // scrolable bar ==>
            overflowX: 'hidden' ,
            overflowY: 'auto',
            maxHeight: '300px', 
            paddingTop: '0px',
            backgroundColor: '#0A293A',
            '&::-webkit-scrollbar': {
                width: '2px', 
                background: '#58bd7d00'
            },
            '&::-webkit-scrollbar-track': {
                background: '#58bd7d00', 
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#888', 
                borderRadius: '5px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
                background: '#555',
            },
        }),
    };


    if (loading) {
        return  <div className='loaderWrapper BoxLoader' ><span class="loader"></span></div>; // Render loading indicator while fetching data
    }

    return (
        <>
            <div className='heroTopCoin mb-4 heroBox flex items-center' dir='rtl' >
                 <div>
                   <img width={40} height={40} className='ml-2' src={BTC} />
                 </div>
                    <div className='' >
                     <div className="coinCardTitle mb-1">بیت کوین<span className="coinSlug">BTC</span></div>
                     <span className="coinCardPrice">{topCoin}</span>
                    </div>
            </div>
            
            <div className='coinCal' >
                <div className='heroCalculator relative' >
                    <div className='coinSection'>
                        {selectedCoin && (
                            <div className='mb-4'  dir='rtl' >
                                <label>دریافت می کنید</label>
                                <div className='flex items-center justify-center coinGroup'>
                                    <div className='lg:pl-2 w-2/4'> {/* Move this div behind the SelectBox */}
                                        <input
                                            className='w-full inputClass pr-3'
                                            dir='rtl'
                                            type="number"
                                            placeholder="1"
                                            value={cryptoAmount}
                                            onChange={handleCryptoAmountChange}
                                        />
                                    </div>
                                    <div className='w-2/3 flex justify-end items-center coinSelectParent relative ' >
                                        <img className='pr-2' src={selectedCoin.img} width={24} height={24} alt={selectedCoin.name} />
                                        <Select
                                            options={coins.map(coin => ({
                                                value: coin.name,
                                                label: (
                                                    <div className='flex selectDiv' style={{ color: 'white' ,padding: '10px' , direction: 'rtl'}}>
                                                        <img src={coin.img} alt={coin.name} style={{ width: '17px', marginLeft: '10px' , height: '17px' }} />
                                                        {coin.name}
                                                    </div>
                                                )
                                            }))}
                                            onChange={(selectedOption) => handleCoinChange(selectedOption.value)}
                                            value={selectedCoin ? { value: selectedCoin.name, label: selectedCoin.name } : null}
                                            styles={customStyles} // Apply custom styles
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        {selectedCoin && (
                            <div className='bottomInputs'>
                                <div>
                                    <label>پرداخت می کنید</label>
                                    <input
                                        dir='rtl'
                                        className='rialInput w-full'
                                        type="text"
                                        placeholder={selectedCoin.buy_rate.toLocaleString()}
                                        value={rialPrice}
                                        readOnly
                                        disabled
                                    />
                                </div>

                                <a className='tradeBTN' href='#' >معامله کنید</a>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
     
    );
}

export default CryptoToRialCalculator;
