
import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import Landing from './views/Landing';
import Coin from './views/Coin';

const AppRouter = () => {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={Landing} />
                <Route exact path="/coin" component={Coin} />
            </Switch>
        </Router>
    );
}

export default AppRouter;
