
import Spacer from '../assets/img/white_spacer.svg'

const CoinSteps = () => {
    return (
        // Hero
        <section className="relative mt-20 pt-1 max-w-6xl mx-auto px-4 sm:px-6">
            {/* <h3 className='singleTitle' >معرفی جامع ارز دیجیتال تتر (Tether)</h3> */}
            <div class="text-center" data-aos="zoom-y-out" data-aos-delay="200" >
                <h4 class="subTitle singleSubTitle noBack">مراحل خرید تتر</h4>
                <h2 class="singleTitle singlePageTitle" dir='rtl'>
                    چگونه تتر بخریم؟
                </h2>
            </div>


            <div className='lg:flex items-center mt-10' >
                <div className='coinSteps px-4 text-center' data-aos="zoom-y-out" data-aos-delay="600" >
                    <b>04</b>
                    <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.3933 3H43.5835C46.7647 3 50.5162 5.36032 51.9267 8.27241L59.1294 23.0474C60.87 26.6032 59.9097 31.6611 57.0586 34.3279L36.2608 53.609C32.8095 56.797 27.1974 56.797 23.7461 53.609L2.94827 34.3279C0.0671863 31.6611 -0.863164 26.6032 0.877491 23.0474L8.0802 8.27241C9.46072 5.36032 13.2121 3 16.3933 3Z" fill="#58BD7D"/>
                    <path d="M42.3124 16.561H17.6553C16.3917 16.561 15.3438 17.609 15.3438 18.8726C15.3438 20.1363 16.3917 21.1842 17.6553 21.1842H27.6723V37.3654C27.6723 38.6291 28.7202 39.677 29.9839 39.677C31.2475 39.677 32.2955 38.6291 32.2955 37.3654V21.1842H42.3124C43.576 21.1842 44.624 20.1363 44.624 18.8726C44.624 17.609 43.576 16.561 42.3124 16.561Z" fill="#255C4A"/>
                    </svg>


                    <span className='csHeading' >
                    خرید سریع تتر
                    </span>
                    <p className='csCaption' >
                    به سادگی تتر یا هر ارز دیجیتال دیگری که تمایل دارید را در کیف پول امن خود دریافت کنید.
                    </p>
                </div>
                <img className='spacer' src={Spacer} data-aos="zoom-y-out" data-aos-delay="500" />
                <div className='coinSteps px-4 text-center' data-aos="zoom-y-out" data-aos-delay="500" >
                    <b>03</b>
                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M56.6666 58.6667H7.33325C6.23992 58.6667 5.33325 57.7601 5.33325 56.6667C5.33325 55.5734 6.23992 54.6667 7.33325 54.6667H56.6666C57.7599 54.6667 58.6666 55.5734 58.6666 56.6667C58.6666 57.7601 57.7599 58.6667 56.6666 58.6667Z" fill="#58BD7D"/>
                    <path opacity="0.4" d="M54.9064 36.5333L35.6264 55.8133C31.8397 59.6 25.733 59.6 21.973 55.84L9.67969 43.5467L42.6397 10.5867L54.933 22.88C58.693 26.64 58.693 32.7467 54.9064 36.5333Z" fill="#58BD7D"/>
                    <path d="M42.6402 10.5864L9.6535 43.5464L7.22683 41.1197C3.46683 37.3597 3.46683 31.253 7.2535 27.4664L26.5335 8.18636C30.3202 4.39969 36.4268 4.39969 40.1868 8.15969L42.6402 10.5864Z" fill="#58BD7D"/>
                    <path d="M34.3729 46.933L30.7729 50.533C30.0262 51.2797 28.8262 51.2797 28.0795 50.533C27.3329 49.7864 27.3329 48.5864 28.0795 47.8397L31.6795 44.2397C32.4262 43.493 33.6262 43.493 34.3729 44.2397C35.1195 44.9864 35.1195 46.1864 34.3729 46.933Z" fill="#58BD7D"/>
                    <path d="M46.0529 35.2533L38.8796 42.4267C38.1329 43.1733 36.9329 43.1733 36.1862 42.4267C35.4396 41.68 35.4396 40.48 36.1862 39.7333L43.3596 32.56C44.1062 31.8133 45.3062 31.8133 46.0529 32.56C46.7729 33.3067 46.7729 34.5067 46.0529 35.2533Z" fill="#58BD7D"/>
                    </svg>

                    <span className='csHeading' >
                    شارژ حساب تومانی
                    </span>
                    <p className='csCaption' >
                    از طریق درگاه امن بانکی سایت نسبت به واریز وجه خود و شارژ حساب تومانی اقدام کنید.
                    </p>
                </div>
                <img className='spacer' src={Spacer} data-aos="zoom-y-out" data-aos-delay="400" />
                <div className='coinSteps px-4 text-center' data-aos="zoom-y-out" data-aos-delay="300" >
                    <b>02</b>
                    <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_200_733)">
                    <path opacity="0.4" d="M5.38659 25.6666C4.23992 25.6666 3.33325 24.7599 3.33325 23.6133V17.4533C3.33325 10.2266 9.22659 4.33325 16.4533 4.33325H22.6133C23.7599 4.33325 24.6666 5.23992 24.6666 6.38659C24.6666 7.53325 23.7599 8.43992 22.6133 8.43992H16.4533C11.4666 8.43992 7.43992 12.4933 7.43992 17.4533V23.6133C7.43992 24.7599 6.50659 25.6666 5.38659 25.6666Z" fill="#58BD7D"/>
                    <path opacity="0.4" d="M54.6133 25.6666C53.4933 25.6666 52.5599 24.7599 52.5599 23.6133V17.4533C52.5599 12.4666 48.5066 8.43992 43.5466 8.43992H37.3866C36.2399 8.43992 35.3333 7.50659 35.3333 6.38659C35.3333 5.26659 36.2399 4.33325 37.3866 4.33325H43.5466C50.7733 4.33325 56.6666 10.2266 56.6666 17.4533V23.6133C56.6666 24.7599 55.7599 25.6666 54.6133 25.6666Z" fill="#58BD7D"/>
                    <path d="M43.5464 57.6666H39.8397C38.7197 57.6666 37.7864 56.7599 37.7864 55.6132C37.7864 54.4932 38.693 53.5599 39.8397 53.5599H43.5464C48.533 53.5599 52.5597 49.5066 52.5597 44.5466V40.8666C52.5597 39.7466 53.4664 38.8132 54.613 38.8132C55.733 38.8132 56.6664 39.7199 56.6664 40.8666V44.5466C56.6664 51.7732 50.773 57.6666 43.5464 57.6666Z" fill="#58BD7D"/>
                    <path d="M22.6133 57.6666H16.4533C9.22659 57.6666 3.33325 51.7733 3.33325 44.5466V38.3866C3.33325 37.2399 4.23992 36.3333 5.38659 36.3333C6.53325 36.3333 7.43992 37.2399 7.43992 38.3866V44.5466C7.43992 49.5333 11.4933 53.5599 16.4533 53.5599H22.6133C23.7333 53.5599 24.6666 54.4666 24.6666 55.6133C24.6666 56.7599 23.7599 57.6666 22.6133 57.6666Z" fill="#58BD7D"/>
                    <path d="M47.2266 28.9465H43.6H16.4H12.7733C11.6266 28.9465 10.72 29.8799 10.72 30.9999C10.72 32.1199 11.6266 33.0532 12.7733 33.0532H16.4H43.6H47.2266C48.3733 33.0532 49.28 32.1199 49.28 30.9999C49.28 29.8799 48.3733 28.9465 47.2266 28.9465Z" fill="#58BD7D"/>
                    <path d="M16.3997 36.1733V37.0533C16.3997 41.48 19.973 45.0533 24.3997 45.0533H35.5997C40.0263 45.0533 43.5997 41.48 43.5997 37.0533V36.1733C43.5997 35.8533 43.3597 35.6133 43.0397 35.6133H16.9597C16.6397 35.6133 16.3997 35.8533 16.3997 36.1733Z" fill="#58BD7D"/>
                    <path opacity="0.4" d="M16.3997 25.8265V24.9465C16.3997 20.5199 19.973 16.9465 24.3997 16.9465H35.5997C40.0263 16.9465 43.5997 20.5199 43.5997 24.9465V25.8265C43.5997 26.1465 43.3597 26.3865 43.0397 26.3865H16.9597C16.6397 26.3865 16.3997 26.1465 16.3997 25.8265Z" fill="#58BD7D"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_200_733">
                    <rect width="60" height="60" fill="white" transform="translate(0 0.645264)"/>
                    </clipPath>
                    </defs>
                    </svg>


                    <span className='csHeading' >فرایند احراز هویت</span>
                    <p className='csCaption' >
                    برای شروع بکار نیاز است یکبار و در کمتر از 5 دقیقه فرایند احراز هویت خود را انجام دهید.
                    </p>
                </div>
                <img className='spacer' src={Spacer} data-aos="zoom-y-out" data-aos-delay="200"  />
                <div className='coinSteps px-4 text-center' data-aos="zoom-y-out" data-aos-delay="100">
                    <b>01</b>
                    <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_200_738)">
                    <path opacity="0.4" d="M41.3068 8.7334H18.6934C12.1068 8.7334 6.77344 14.0934 6.77344 20.6534V45.7467C6.77344 52.3067 12.1334 57.6667 18.6934 57.6667H41.2801C47.8668 57.6667 53.2001 52.3067 53.2001 45.7467V20.6534C53.2268 14.0667 47.8668 8.7334 41.3068 8.7334Z" fill="#58BD7D"/>
                    <path d="M36.2667 4.33325H23.7334C20.96 4.33325 18.6934 6.57325 18.6934 9.34659V11.8533C18.6934 14.6266 20.9334 16.8666 23.7067 16.8666H36.2667C39.04 16.8666 41.28 14.6266 41.28 11.8533V9.34659C41.3067 6.57325 39.04 4.33325 36.2667 4.33325Z" fill="#58BD7D"/>
                    <path d="M37.9999 33.5334H19.3333C18.2399 33.5334 17.3333 32.6268 17.3333 31.5334C17.3333 30.4401 18.2399 29.5334 19.3333 29.5334H37.9999C39.0933 29.5334 39.9999 30.4401 39.9999 31.5334C39.9999 32.6268 39.0933 33.5334 37.9999 33.5334Z" fill="#58BD7D"/>
                    <path d="M31.0133 44.2H19.3333C18.2399 44.2 17.3333 43.2933 17.3333 42.2C17.3333 41.1066 18.2399 40.2 19.3333 40.2H31.0133C32.1066 40.2 33.0133 41.1066 33.0133 42.2C33.0133 43.2933 32.1066 44.2 31.0133 44.2Z" fill="#58BD7D"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_200_738">
                    <rect width="60" height="60" fill="white" transform="translate(0 0.645264)"/>
                    </clipPath>
                    </defs>
                    </svg>

                    <span className='csHeading' >ثبت نام در آلمااکس</span>
                    <p className='csCaption' >در اولین قدم می‌‎بایست در سایت آلما اکس ثبت‌نام کرده و موبایل و ایمیل خود را تایید بفرمایید.</p>
                </div>
            </div>
        </section>        
    );
}

export default CoinSteps;
