
import CoinBannerIMG from '../assets/img/coinBanner.png'

const CoinBanner = () => {
    return (
        
        // Hero
        <section className="relative mt-5 pt-1 max-w-6xl mx-auto px-4 sm:px-6">
            {/* <h3 className='singleTitle' >معرفی جامع ارز دیجیتال تتر (Tether)</h3> */}
            <div class="text-right" data-aos="zoom-y-out" data-aos-delay="200" >
                <h4 class="subTitle singleSubTitle">بیشتر بخوانید</h4>
                <h2 class="singleTitle singlePageTitle" dir='rtl'>
                معرفی جامع ارز دیجیتال تتر (Tether)
                </h2>
            </div>

            <div className='HomeBanner p-8 singleBanner mt-5 pb-20 relative' data-aos="zoom-y-out" data-aos-delay="300" >
                <p>
                تتر (Tether) یکی از پرکاربردترین ارزهای دیجیتالی است که در اوایل سال ۲۰۱۴ میلادی وارد دنیای کریپتوکارنسی‌ها شد. این ارز دیجیتال در ابتدا با نام ریل کوین (Realcoin) وارد بازار شد، اما بعدها توسط شرکت iFinex که مالک اصلی ریل کوین بود، به تتر تغییر نام داده شد. این شرکت همچنین مالک صرافی بیت‌فینیکس (Bitfinex) است که یکی از بزرگ‌ترین صرافی‌های دنیا است.
                در واقع تتر یک استیبل‌کوین است، و بزرگترین ویژگی آن این است که از تمام نوسانات مثبت و منفی بازار ارزهای دیجیتال در امان است. به عبارت دیگر، تتر ابزاری است که برای کنترل عدم پایداری قیمت‌ها در معاملات رمزارزها استفاده می‌شود.
                </p>
                <a className="BuTTon btnGreen bg-blue-600 hover:bg-blue-700 w-full sm:w-auto sm:mb-0 mt-5" href="#0">
                    حرفه ای معامله کنید
                </a>

                <img className='singleBannerImg' src={CoinBannerIMG} width={270} />
            </div>
        </section>    
            
    );
}

export default CoinBanner;
