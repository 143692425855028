
import HomeHero from '../components/HomHero'
import CoinsSlider from '../components/CoinsSlider'
import HomeTabs from '../components/HomeTabs';
import CoinsTable from '../components/CoinsTable';
import HomeBanner from '../components/HomeBanner';
import HomeBlog from '../components/HomeBlog';
import HomeCaptions from '../components/HomeCaptions';
import Accordions from '../components/Accordions';

const Landing = () => {
    return (
        <div className='landing' >
            {/* Hero */}
            <HomeHero />     
            {/* Coins */}
            <CoinsSlider />     
            {/* Home Tabs */}
            <HomeTabs />     
            {/* Coins Table */}
            <CoinsTable />  
            {/* Home Banner */}
            <HomeBanner />  
            {/* Home Blog */}
            <HomeBlog />
            {/* Home Captions */}
            <HomeCaptions /> 
            {/* Accordions */}
            <Accordions /> 
        </div>
    );
}

export default Landing;
