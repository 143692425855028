
import VideoPlaceHolder from '../assets/img/coinPlaceHolder.png'

const CoinVideo = () => {
    return (
        // Hero
        <section className="relative pt-4 mt-5 pt-20 max-w-6xl mx-auto px-4 sm:px-6 mt-10">
            {/* <h3 className='singleTitle' >معرفی جامع ارز دیجیتال تتر (Tether)</h3> */}
            <div class="text-right mt-20"  data-aos="zoom-y-out" data-aos-delay="100">
                <h4 class="subTitle singleSubTitle">بیشتر بخوانید</h4>
                <h2 class="singleTitle singlePageTitle" dir='rtl'>
                با تتر بیشتر آشنا شوید؟
                </h2>
            </div>

            <div className='singleCap'  data-aos="zoom-y-out" data-aos-delay="200">
                <p>
                تتر یا USDT یک استیبل کوین یا رمزارز قیمت‌ثابت است که پشتوانهٔ آن دلار آمریکاست. به همین دلیل قیمت آن همواره برابر با یک دلار است. تتر بزرگترین استیبل کوین بازار ارزهای دیجیتال است و بالاترین میزان خرید و فروش روزانه (حتی بیشتر از بیت کوین) را در میان تمام ارزهای دیجیتال دارد.
                </p>
            </div>

            <img className='my-10' src={VideoPlaceHolder}  data-aos="zoom-y-out" data-aos-delay="300" />


            <div className='singleCap'  data-aos="zoom-y-out" data-aos-delay="200">
                <p>
                ایدهٔ اولیهٔ خلق USDT در سال ۲۰۱۲ توسط جی آر ویلت (J.R. Willet) مطرح شد. اما بنیان‌گذاران اولیهٔ بنیاد تتر، براک پیرس (Brock Pierce)،‌ ریو کالینز (Reeve Collins) و کریگ سلارس (Craig Sellars) بودند.
در سال ۲۰۱۲، جی آر ویلت (J.R. Willett) مقاله‌ای منتشر کرد و در آن احتمال ایجاد یک رمزارز بر بستر بلاک چین بیت کوین را بررسی کرد. او ایدهٔ ایجاد رمزارز جدیدی به‌نام مسترکوین را مطرح کرد و برای این کار بنیادی به‌نام بنیاد مسترکوین را به وجود آورد. بعدها نام این بنیاد به اومنی (Omni) تغییر کرد. اومنی درواقع ایده‌ای بود که امکان استفاده از راهکارهای لایهٔ دوم در بلاک چین بیت کوین را مطرح می‌کرد. اومنی بعدها به زیرساخت فناورانه‌ای برای ایجاد رمزارز «ریل کوین» (RealCoin) تبدیل شد. این رمزارز در ژانویه ۲۰۱۴ به وجود آمد و مدتی بعد نام آن به تتر تغییر کرد.
تتر به‌صورت غیرمتمرکز فعالیت می‌کند، اما بنیاد تتر که در هنگ کنگ مستقر شده است، مسئول پاسخ میزان عرضه و تقاضا در بازار است. این بنیاد این کار را از طریق ضرب سکه‌های جدید یا بازخرید و سوزاندن توکن‌ها انجام می‌دهد. شرکتِ مادر بنیاد تتر، شرکت آیفینکس (iFinex) است. مالکیت صرافی بزرگ بیتفینکس هم متعلق به همین شرکت است.
                </p>
            </div>

        </section>        
    );
}

export default CoinVideo;
