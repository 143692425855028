import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const Accordions = () => {
    return (
        // Accordions
        <section>

        
        <div className="max-w-6xl mx-auto px-4 sm:px-6 mt-0 mb-0">
          <div className="pb-12 md:pb-20">
              <div className="twoSecHeader flex items-center px-4 aos-init aos-animate" data-aos="zoom-y-out" data-aos-delay="100"><span>.</span><span className="ml-auto">سوالات متداول</span></div>
              <div className="Accordions mt-6">
                  <Accordion>
                      <AccordionItem data-aos="zoom-y-out" data-aos-delay="100">
                          <AccordionItemHeading>
                              <AccordionItemButton>
                              بهترین راه خرید تتر (USDT) چیست؟
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                              <p>
                              بله، شما می‌توانید با خرید و فروش مستقیم در همتاپی، ارز دیجیتال تتر (USDT) را با مناسب‌ترین قیمت بازار و بدون یک ریال کارمزد خرید و فروش کنید.
                              </p>
                          </AccordionItemPanel>
                      </AccordionItem>
  
                      <AccordionItem data-aos="zoom-y-out" data-aos-delay="200">
                          <AccordionItemHeading>
                              <AccordionItemButton>
                              چگونه می‌توان تتر (USDT) خریداری کرد؟
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                              <p>
                              بله، شما می‌توانید با خرید و فروش مستقیم در همتاپی، ارز دیجیتال تتر (USDT) را با مناسب‌ترین قیمت بازار و بدون یک ریال کارمزد خرید و فروش کنید.
  
                              </p>
                          </AccordionItemPanel>
                      </AccordionItem>
  
                      <AccordionItem data-aos="zoom-y-out" data-aos-delay="300">
                          <AccordionItemHeading>
                              <AccordionItemButton>
                              آیا امکان خرید و فروش تتر (USDT) بدون پرداخت کارمزد وجود دارد؟ 
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                              <p>
                              بله، شما می‌توانید با خرید و فروش مستقیم در همتاپی، ارز دیجیتال تتر (USDT) را با مناسب‌ترین قیمت بازار و بدون یک ریال کارمزد خرید و فروش کنید.
  
                              </p>
                          </AccordionItemPanel>
                      </AccordionItem>
  
                      <AccordionItem data-aos="zoom-y-out" data-aos-delay="400">
                          <AccordionItemHeading>
                              <AccordionItemButton>
                              قیمت تتر (USDT) چگونه تعیین می‌شود؟ 
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                              <p>
                              بله، شما می‌توانید با خرید و فروش مستقیم در همتاپی، ارز دیجیتال تتر (USDT) را با مناسب‌ترین قیمت بازار و بدون یک ریال کارمزد خرید و فروش کنید.
  
                              </p>
                          </AccordionItemPanel>
                      </AccordionItem>
  
                      <AccordionItem data-aos="zoom-y-out" data-aos-delay="500">
                          <AccordionItemHeading>
                              <AccordionItemButton>
                              آیا می‌توان با تومان تتر (USDT) خرید؟
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                              <p>
                              بله، شما می‌توانید با خرید و فروش مستقیم در همتاپی، ارز دیجیتال تتر (USDT) را با مناسب‌ترین قیمت بازار و بدون یک ریال کارمزد خرید و فروش کنید.
  
                              </p>
                          </AccordionItemPanel>
                      </AccordionItem>
  
                      <AccordionItem data-aos="zoom-y-out" data-aos-delay="600">
                          <AccordionItemHeading>
                              <AccordionItemButton>
                              آیا می‌توان تتر را استخراج کرد؟
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                              <p>
                              بله، شما می‌توانید با خرید و فروش مستقیم در همتاپی، ارز دیجیتال تتر (USDT) را با مناسب‌ترین قیمت بازار و بدون یک ریال کارمزد خرید و فروش کنید.
  
                              </p>
                          </AccordionItemPanel>
                      </AccordionItem>
                  </Accordion>
              </div>
          </div>
        </div>
      </section>
    );
}

export default Accordions;
