
import btcIcon from '../assets/img/btc.svg'
import chart from '../assets/img/chart.svg'
import React, { useState, useEffect } from 'react';

const CoinTable = () => {

      const [Table, setDivs] = useState([]);
      const [isLoading, setIsLoading] = useState(true);
  
      useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch('https://app.alma.exchange/api/coins');
            if (!response.ok) {
              throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            setDivs(data.data.slice(0, 15).map((coin, index) => (
              <tr data-aos="zoom-y-out" data-aos-delay={index + "00"}>
                <td>
                  <div className="flex items-center">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.3485 5.95574C6.28114 6.11706 6.12928 6.2272 5.95502 6.24111L2.78884 6.49396C2.37455 6.52705 2.20692 7.04438 2.52307 7.31414L4.93269 9.37023C5.06617 9.48413 5.12448 9.66327 5.08362 9.83391L4.34697 12.9101C4.25035 13.3136 4.68891 13.6331 5.04337 13.4175L7.75764 11.766C7.90664 11.6754 8.09376 11.6754 8.24277 11.766L10.957 13.4175C11.3115 13.6331 11.7501 13.3136 11.6534 12.9101L10.9168 9.83391C10.8759 9.66327 10.9342 9.48413 11.0677 9.37023L13.4773 7.31414C13.7935 7.04438 13.6259 6.52705 13.2116 6.49396L10.0454 6.24111C9.87113 6.2272 9.71926 6.11706 9.65191 5.95574L8.43084 3.03132C8.27114 2.64885 7.72927 2.64885 7.56957 3.03132L6.3485 5.95574ZM10.6786 4.9541L9.66123 2.51759C9.04523 1.04231 6.95517 1.04232 6.33918 2.51759L5.32183 4.9541L2.6827 5.16486C1.08475 5.29247 0.43818 7.2879 1.65761 8.32842L3.66328 10.0398L3.0503 12.5996C2.6776 14.156 4.36922 15.3884 5.73641 14.5565L8.0002 13.1792L10.264 14.5565C11.6312 15.3884 13.3228 14.156 12.9501 12.5996L12.3371 10.0398L14.3428 8.32842C15.5622 7.28789 14.9156 5.29247 13.3177 5.16486L10.6786 4.9541Z" fill="#777E91"/>
                    </svg> <b className='mr-3'>{index}</b>
                  </div>
                </td>
                <td>
                  <div className="flex items-center">
                    <img className='ml-3' src={coin.img} width={32}  alt={coin.asset}  />
                    <div className='lg:flex items-center' >
                      <span className="tableCoinName">{coin.name}</span>
                      <small className='mr-2'>{coin.asset}</small>
                    </div>
                  </div>
                </td>
                <td>{coin.sell_rate}</td>
                <td className='up' ><b>+ 00.00 %</b></td>
                <td className='up' ><b>+ 00.00 %</b></td>
                <td>-- $</td>
                <td><img className='ml-3' src={chart} width={96}  alt="btc chart"  /></td>

              </tr>
            )));
            setIsLoading(false);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
        
        fetchData();
    
        return () => { };
      }, []); 

    return (
        // Hero
        <section className="relative">

        <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="TableHeader w-100 lg:flex items-center mb-12">
          <div className="flex items-center" data-aos="zoom-y-out" data-aos-delay="200">
            <div className="TableSearch mr-4">
              <div className="relative flex items-center max-w-xs ">
                  <input type="email" dir='rtl' className="form-input w-full text-gray-800 px-3 py-2 pl-12 text-sm borderR tableSearch"  placeholder="جستجو کریپتو" required />
                  <button type="submit" className="absolute inset-0 left-3" aria-label="Subscribe">
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z" stroke="#031B29" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              <path opacity="0.4" d="M22 22L20 20" stroke="#031B29" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                  </button>
              </div>
            </div>
            <div className="TableToggle" >
              <button className='active mr-5' >تومان</button>
              <button>تتر</button>
            </div>
          </div>
  
          <div className="TableToggle ml-auto mt-5 lg:mt-0 text-right" data-aos="zoom-y-out" data-aos-delay="100">
              <button>جدید ترین</button>
              <button className='ml-5'>بیشترین شد</button>
              <button className='active ml-5' >محبوب ترین</button>
          </div>
        </div>
        <table className="table-fixed w-100 coinTable" data-aos="zoom-y-out" data-aos-delay="300">
          <thead>
            <tr>
              <th>#</th>
              <th scope="col" className='cryptoTH' >
                <div className="flex items-center">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.86323 6.80441C5.12358 7.06476 5.54569 7.06476 5.80604 6.80441L7.66797 4.94248L9.5299 6.80441C9.79025 7.06476 10.2124 7.06476 10.4727 6.80441C10.7331 6.54406 10.7331 6.12195 10.4727 5.8616L8.13937 3.52827C7.87902 3.26792 7.45691 3.26792 7.19656 3.52827L4.86323 5.8616C4.60288 6.12195 4.60288 6.54406 4.86323 6.80441Z" fill="#031B29"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.86323 9.1951C5.12358 8.93475 5.54569 8.93475 5.80604 9.1951L7.66797 11.057L9.5299 9.1951C9.79025 8.93475 10.2124 8.93475 10.4727 9.1951C10.7331 9.45545 10.7331 9.87756 10.4727 10.1379L8.13937 12.4712C7.87902 12.7316 7.45691 12.7316 7.19656 12.4712L4.86323 10.1379C4.60288 9.87756 4.60288 9.45545 4.86323 9.1951Z" fill="#031B29"/>
                  </svg> رمزارز
                </div>
              </th>
              <th scope="col">
                <div className="flex items-center">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.86323 6.80441C5.12358 7.06476 5.54569 7.06476 5.80604 6.80441L7.66797 4.94248L9.5299 6.80441C9.79025 7.06476 10.2124 7.06476 10.4727 6.80441C10.7331 6.54406 10.7331 6.12195 10.4727 5.8616L8.13937 3.52827C7.87902 3.26792 7.45691 3.26792 7.19656 3.52827L4.86323 5.8616C4.60288 6.12195 4.60288 6.54406 4.86323 6.80441Z" fill="#031B29"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.86323 9.1951C5.12358 8.93475 5.54569 8.93475 5.80604 9.1951L7.66797 11.057L9.5299 9.1951C9.79025 8.93475 10.2124 8.93475 10.4727 9.1951C10.7331 9.45545 10.7331 9.87756 10.4727 10.1379L8.13937 12.4712C7.87902 12.7316 7.45691 12.7316 7.19656 12.4712L4.86323 10.1379C4.60288 9.87756 4.60288 9.45545 4.86323 9.1951Z" fill="#031B29"/>
                  </svg> آخرین قیمت
                </div>
              </th>
              <th scope="col">
                <div className="flex items-center">
                  <b>%</b> نوسان روزانه
                </div>
              </th>
              <th scope="col">
                  <b>%</b> نوسان هفتگی
              </th>
              <th scope="col">
                <div className="flex items-center">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.66732 2.5C2.12755 2.5 2.50065 2.8731 2.50065 3.33333V15C2.50065 15.4602 2.87375 15.8333 3.33398 15.8333H18.334C18.7942 15.8333 19.1673 16.2064 19.1673 16.6667C19.1673 17.1269 18.7942 17.5 18.334 17.5H3.33398C1.95327 17.5 0.833984 16.3807 0.833984 15V3.33333C0.833984 2.8731 1.20708 2.5 1.66732 2.5Z" fill="#031B29"/>
                  <path d="M14.9993 4.1665C14.5391 4.1665 14.166 4.5396 14.166 4.99984V13.3332C14.166 13.7934 14.5391 14.1665 14.9993 14.1665C15.4596 14.1665 15.8327 13.7934 15.8327 13.3332V4.99984C15.8327 4.5396 15.4596 4.1665 14.9993 4.1665Z" fill="#031B29"/>
                  <path d="M8.33268 5.83317C7.87244 5.83317 7.49935 6.20627 7.49935 6.6665V13.3332C7.49935 13.7934 7.87244 14.1665 8.33268 14.1665C8.79292 14.1665 9.16602 13.7934 9.16602 13.3332V6.6665C9.16602 6.20627 8.79292 5.83317 8.33268 5.83317Z" fill="#031B29"/>
                  <path d="M4.99935 10.8332C4.53911 10.8332 4.16602 11.2063 4.16602 11.6665V13.3332C4.16602 13.7934 4.53911 14.1665 4.99935 14.1665C5.45959 14.1665 5.83268 13.7934 5.83268 13.3332V11.6665C5.83268 11.2063 5.45959 10.8332 4.99935 10.8332Z" fill="#031B29"/>
                  <path d="M10.8327 8.33317C10.8327 7.87293 11.2058 7.49984 11.666 7.49984C12.1263 7.49984 12.4993 7.87293 12.4993 8.33317V13.3332C12.4993 13.7934 12.1263 14.1665 11.666 14.1665C11.2058 14.1665 10.8327 13.7934 10.8327 13.3332V8.33317Z" fill="#031B29"/>
                  </svg>
                  حجم
  
                </div>
              </th>
              <th scope="col">نمودار</th>
            </tr>
          </thead>
          <tbody>

            {/* Conditional rendering of loader */}
            {isLoading ? (
               <div className='loaderWrapper' ><span class="loader"></span></div>
            ) : (
              Table 
            )}
  
            {/* <tr data-aos="zoom-y-out" data-aos-delay="500">
              <td>
                <div className="flex items-center">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.3485 5.95574C6.28114 6.11706 6.12928 6.2272 5.95502 6.24111L2.78884 6.49396C2.37455 6.52705 2.20692 7.04438 2.52307 7.31414L4.93269 9.37023C5.06617 9.48413 5.12448 9.66327 5.08362 9.83391L4.34697 12.9101C4.25035 13.3136 4.68891 13.6331 5.04337 13.4175L7.75764 11.766C7.90664 11.6754 8.09376 11.6754 8.24277 11.766L10.957 13.4175C11.3115 13.6331 11.7501 13.3136 11.6534 12.9101L10.9168 9.83391C10.8759 9.66327 10.9342 9.48413 11.0677 9.37023L13.4773 7.31414C13.7935 7.04438 13.6259 6.52705 13.2116 6.49396L10.0454 6.24111C9.87113 6.2272 9.71926 6.11706 9.65191 5.95574L8.43084 3.03132C8.27114 2.64885 7.72927 2.64885 7.56957 3.03132L6.3485 5.95574ZM10.6786 4.9541L9.66123 2.51759C9.04523 1.04231 6.95517 1.04232 6.33918 2.51759L5.32183 4.9541L2.6827 5.16486C1.08475 5.29247 0.43818 7.2879 1.65761 8.32842L3.66328 10.0398L3.0503 12.5996C2.6776 14.156 4.36922 15.3884 5.73641 14.5565L8.0002 13.1792L10.264 14.5565C11.6312 15.3884 13.3228 14.156 12.9501 12.5996L12.3371 10.0398L14.3428 8.32842C15.5622 7.28789 14.9156 5.29247 13.3177 5.16486L10.6786 4.9541Z" fill="#777E91"/>
                  </svg> <b className='mr-3'>2</b>
                </div>
              </td>
              <td>
                <div className="flex items-center">
                  <img className='ml-3' src={btcIcon} width={32}  alt="btc"  />
                  <div className='lg:flex items-center' >
                    <span className="tableCoinName">بیت کوین</span>
                    <small className='mr-2'>BTC</small>
                  </div>
                </div>
              </td>
              <td>345,000,000</td>
              <td className='up' ><b>+ 6.4 %</b></td>
              <td className='down'><b>+ 6.4 %</b></td>
              <td>345,000 $</td>
              <td><img className='ml-3' src={chart} width={96}  alt="btc chart"  /></td>
            </tr> */}
  


          </tbody>
        </table>
          <div className="text-center" data-aos="zoom-y-out" data-aos-delay="900">
            <a className="BuTTon btnGreen bg-blue-600 hover:bg-blue-700 w-full sm:w-auto sm:mb-0 mt-5" href="#0">مشاهده همه ارزها</a>
          </div>
        </div>
      </section> 
    );
}

export default CoinTable;
